import React, { useState, useEffect } from "react";
import { Container, Tab, Nav, Table, Row, Col } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import Loader from "../Component/Loader";
import nodata from "../asset/images/ListingSoon.webp";
import { Helmet } from "react-helmet";
import IPO_Dekho_Feture_img from "../asset/images/IPO_Dekho_Feture_img.jpg";

const GMP = () => {
  const [upcomingMainLineIpos, setupcomingMainLineIpos] = useState([]);
  const [liveMainLineIpos, setliveMainLineIpos] = useState([]);
  const [upcomingSMEIpos, setupcomingSMEIpos] = useState([]);
  const [liveSMEIpos, setLiveSMEIpos] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        const mainUpcomingResponse = await axios.post(
          "https://app.ipodekho.com/GetMainLineIpo",
          {
            CategoryForIPOS: "MainlineIPO",
            type: "Upcoming",
          }
        );
        const mainLineLiveResponse = await axios.post(
          "https://app.ipodekho.com/GetMainLineIpo",
          {
            CategoryForIPOS: "MainlineIPO",
            type: "Live",
          }
        );
        const smeUpcomingResponse = await axios.post(
          "https://app.ipodekho.com/GetMainLineIpo",
          {
            CategoryForIPOS: "SmeIPO",
            type: "Upcoming",
          }
        );
        const smeLiveResponse = await axios.post(
          "https://app.ipodekho.com/GetMainLineIpo",
          {
            CategoryForIPOS: "SmeIPO",
            type: "Live",
          }
        );
        setupcomingMainLineIpos(mainUpcomingResponse.data.upcomingIpo);
        setliveMainLineIpos(mainLineLiveResponse.data.liveIpo);
        setupcomingSMEIpos(smeUpcomingResponse.data.upcomingIpo);
        setLiveSMEIpos(smeLiveResponse.data.liveIpo);
        setLoading(false); // Stop loading
      } catch (error) {
        console.error("Error fetching IPO data:", error);
        setLoading(false); // Stop loading even if there is an error
      }
    };
    fetchData();
  }, []);
  function formatDateRange(openDate, closeDate) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const open = new Date(openDate).toLocaleDateString(undefined, options);
    const close = new Date(closeDate).toLocaleDateString(undefined, options);
    return (
      <>
        {open} to <br /> {close}
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Live IPO GMP - IPO DEKHO</title>
        <meta
          name="description"
          content="This page offers real-time Grey Market Premium (GMP) data for SME and Mainboard IPOs, helping investors estimate potential listing gains by adding GMP to the issue price. We also include Subject to Sauda prices and calculate estimated listing prices, keeping investors informed before official stock market listings."
        />
        <meta property="og:url" content="https://ipodekho.com/gmp" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Live IPO GMP - IPO DEKHO" />
        <meta
          property="og:description"
          content="This page offers real-time Grey Market Premium (GMP) data for SME and Mainboard IPOs, helping investors estimate potential listing gains by adding GMP to the issue price. We also include Subject to Sauda prices and calculate estimated listing prices, keeping investors informed before official stock market listings."
        />
        <meta property="og:image" content={IPO_Dekho_Feture_img} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="https://ipodekho.com/gmp" />
        <meta property="twitter:url" content="https://ipodekho.com/gmp" />
        <meta name="twitter:title" content="Live IPO GMP - IPO DEKHO" />
        <meta
          name="twitter:description"
          content="This page offers real-time Grey Market Premium (GMP) data for SME and Mainboard IPOs, helping investors estimate potential listing gains by adding GMP to the issue price. We also include Subject to Sauda prices and calculate estimated listing prices, keeping investors informed before official stock market listings."
        />
        <meta name="twitter:image" content={IPO_Dekho_Feture_img} />

        <link rel="canonical" href="https://ipodekho.com/gmp" />
      </Helmet>
      <section className="ipo-detail">
        <Container>
          <div className="section_title">
            <h1>
              Live IPO <span>GMP</span>
            </h1>
            <p>
              The Grey Market is an unofficial market where IPO shares and
              applications are traded before the stock is listed. Although
              investors may avoid trading in the grey market, the Grey Market
              Premium (GMP) offers insights into potential listing gains. By
              adding the GMP to the IPO issue price, investors can estimate the
              expected listing price. Here, we provide the GMP for upcoming and
              current SME and Mainboard IPOs, along with the Subject to Sauda
              price, calculating the estimated listing price by summing the GMP
              with the IPO cap price.
            </p>
          </div>
          <div class="ipo-detail-tabs-upcoming">
            <div className="ipo-detail-inner-tabs">
              <Tab.Container defaultActiveKey="mainboard">
                <nav className="inner-tabs">
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="mainboard">
                        <span>Mainboard</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="sme">
                        <span>SME</span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </nav>
                <Tab.Content>
                  <Tab.Pane eventKey="mainboard">
                    <div className="d-none d-md-block">
                      {loading ? (
                        <div className="text-center">
                          <Loader />
                        </div>
                      ) : (
                        <>
                          <Table bordered className="ipo_table gmp_table">
                            {liveMainLineIpos?.length === 0 &&
                            upcomingMainLineIpos?.length === 0 ? (
                              <div>
                                <center>
                                  <img
                                    src={nodata}
                                    className="img-fluid"
                                    alt="sectionbg"
                                  />
                                </center>
                              </div>
                            ) : (
                              <>
                                <thead>
                                  <tr>
                                    <th colSpan="2">IPO Name</th>
                                    <th>Offer Date</th>
                                    <th>Offer Price</th>
                                    <th>Lot Size</th>
                                    <th>GMP</th>
                                    <th colSpan="2">Est Listing</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {[
                                    ...(liveMainLineIpos || []),
                                    ...(upcomingMainLineIpos || []),
                                  ].map((ipo, index) => {
                                    const toPrice =
                                      parseFloat(ipo.toPrice) || 0;
                                    const GMP = parseFloat(ipo.GMP) || 0;
                                    const estListing = toPrice + GMP;
                                    const percentage = (
                                      (GMP * 100) /
                                      toPrice
                                    ).toFixed(2);

                                    let estListingClass = "est_listing_neutral";
                                    if (estListing > ipo.toPrice) {
                                      estListingClass = "est_listing_green";
                                    } else if (estListing < ipo.toPrice) {
                                      estListingClass = "est_listing_red";
                                    }

                                    return (
                                      <tr key={ipo.id}>
                                        <td className="ipo-image">
                                          <img
                                            src={ipo.file}
                                            alt={ipo.companyName}
                                          />
                                        </td>
                                        <td className="ipo-name">
                                          <span
                                            className={`status ${ipo.IPOStatus.toLowerCase()}`}
                                          >
                                            {ipo?.IPOStatus ===
                                              "WaitingAllotment" &&
                                              "Waiting Allotment"}
                                            {ipo?.IPOStatus ===
                                              "AllotmentOut" && "Allotment Out"}
                                            {ipo?.IPOStatus ===
                                              "AllotmentToday" &&
                                              "Allotment Today"}
                                            {ipo?.IPOStatus === "Live" &&
                                              "Live"}
                                            {ipo?.IPOStatus === "Upcoming" &&
                                              "Upcoming"}
                                            {ipo?.IPOStatus === "Listed" &&
                                              "Listed"}
                                          </span>
                                          <Link
                                            className="ipo_name btn btn-primary"
                                            to={`/ipo/${ipo.slug}`}
                                          >
                                            <strong>{ipo.companyName}</strong>
                                          </Link>
                                        </td>
                                        <td className="ipo-date">
                                          {formatDateRange(
                                            ipo.IPOOpenDate,
                                            ipo.IPOCloseDate
                                          )}
                                        </td>
                                        <td className="ipo-price">
                                          {ipo?.fromPrice && ipo?.toPrice
                                            ? `₹${ipo.fromPrice} to ₹${ipo.toPrice}`
                                            : "N/A"}
                                        </td>
                                        <td className="ipo-lot">
                                          {ipo?.lotSize
                                            ? `${ipo.lotSize} Shares`
                                            : "N/A"}
                                        </td>
                                        <td
                                          className={`ipo-gmp ${
                                            ipo.GMP < 0
                                              ? "false"
                                              : ipo.GMP > 0
                                              ? "true"
                                              : "neutral"
                                          }`}
                                        >
                                          {isNaN(ipo.GMP) ? "-" : `₹${ipo.GMP}`}
                                        </td>
                                        <td
                                          className={`ipo-est-listing ${estListingClass}`}
                                        >
                                          {estListing
                                            ? `₹${estListing} (${percentage}%)`
                                            : "-"}
                                        </td>
                                        <td className="ipo-link">
                                          <Link
                                            className="btn btn-primary"
                                            to={`/ipo/${ipo.slug}`}
                                          >
                                            View
                                          </Link>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </>
                            )}
                          </Table>
                        </>
                      )}
                    </div>
                    <div className="d-block d-md-none">
                      {loading ? (
                        <div className="text-center">
                          <Loader />
                        </div>
                      ) : (
                        <>
                          {liveMainLineIpos?.length === 0 &&
                          upcomingMainLineIpos?.length === 0 ? (
                            <div>
                              <center>
                                <img
                                  src={nodata}
                                  class="img-fluid"
                                  alt="sectionbg"
                                />
                              </center>
                            </div>
                          ) : (
                            <>
                              <div className="mobile_ipo text-start">
                                {[
                                  ...(liveMainLineIpos || []),
                                  ...(upcomingMainLineIpos || []),
                                ].map((ipo) => {
                                  const toPrice = parseFloat(ipo.toPrice) || 0;
                                  const GMP = parseFloat(ipo.GMP) || 0;
                                  const estListing = toPrice + GMP;
                                  const percentage = (
                                    (GMP * 100) /
                                    toPrice
                                  ).toFixed(2);

                                  let estListingClass = "est_listing_neutral";
                                  if (estListing > toPrice) {
                                    estListingClass = "est_listing_green";
                                  } else if (estListing < toPrice) {
                                    estListingClass = "est_listing_red";
                                  }

                                  return (
                                    <Link
                                      className="mobile_card"
                                      to={`/ipo/${ipo.slug}`}
                                      key={ipo.id}
                                    >
                                      <span
                                        className={`status ${ipo.IPOStatus.toLowerCase()}`}
                                      >
                                        {ipo?.IPOStatus ===
                                          "WaitingAllotment" &&
                                          "Waiting Allotment"}
                                        {ipo?.IPOStatus === "AllotmentOut" &&
                                          "Allotment Out"}
                                        {ipo?.IPOStatus === "AllotmentToday" &&
                                          "Allotment Today"}
                                        {ipo?.IPOStatus === "Live" && "Live"}
                                        {ipo?.IPOStatus === "Upcoming" &&
                                          "Upcoming"}
                                        {ipo?.IPOStatus === "Listed" &&
                                          "Listed"}
                                      </span>
                                      <Row className="align-items-center">
                                        <Col xs={4}>
                                          <img
                                            src={ipo.file}
                                            alt={ipo.companyName}
                                            className="img-fluid ipo_company_logo"
                                          />
                                        </Col>
                                        <Col xs={8}>
                                          <h6 className="mb-0 ipo_companyName">
                                            {ipo.companyName}
                                          </h6>
                                        </Col>
                                      </Row>
                                      <Row className="top_border">
                                        <Col xs={8} className="border_right">
                                          <p className="small card_title mb-0">
                                            Offer Date
                                          </p>
                                          <p className="mb-0 card_info fw-medium">
                                            {formatDateRange(
                                              ipo.IPOOpenDate,
                                              ipo.IPOCloseDate
                                            )}
                                          </p>
                                        </Col>
                                        <Col xs={4}>
                                          <p className="small card_title mb-0">
                                            Offer Price
                                          </p>
                                          <p className="mb-0 card_info fw-medium">
                                            {ipo?.fromPrice && ipo?.toPrice
                                              ? `₹${ipo.fromPrice} to ₹${ipo.toPrice}`
                                              : "N/A"}
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row className="top_border">
                                        <Col xs={4} className="border_right">
                                          <p className="small card_title mb-0">
                                            Lot Size
                                          </p>
                                          <p className="mb-0 card_info fw-medium">
                                            {ipo?.lotSize
                                              ? `${ipo.lotSize} Shares`
                                              : "N/A"}
                                          </p>
                                        </Col>
                                        <Col xs={3} className="border_right">
                                          <p className="small card_title mb-0">
                                            GMP
                                          </p>
                                          <p
                                            className={`mb-0 ipo-gmp ${
                                              ipo.GMP < 0
                                                ? "false"
                                                : ipo.GMP > 0
                                                ? "true"
                                                : "neutral"
                                            }`}
                                          >
                                            {isNaN(ipo.GMP)
                                              ? "-"
                                              : `₹${ipo.GMP}`}
                                          </p>
                                        </Col>
                                        <Col xs={5}>
                                          <p className="small card_title mb-0">
                                            Est Listing
                                          </p>
                                          <p
                                            className={`mb-0 ipo-est-listing ${estListingClass}`}
                                          >
                                            {estListing
                                              ? `₹${estListing} (${percentage}%)`
                                              : "-"}
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row className="top_border">
                                        <Col>
                                          <p className="ipo_card_link mb-0">
                                            View More Information
                                          </p>
                                        </Col>
                                      </Row>
                                    </Link>
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="sme">
                    <div className="d-none d-md-block">
                      {loading ? (
                        <div className="text-center">
                          <Loader />
                        </div>
                      ) : (
                        <Table bordered className="ipo_table gmp_table">
                          {liveSMEIpos?.length === 0 &&
                          upcomingSMEIpos?.length === 0 ? (
                            <div>
                              <center>
                                <img
                                  src={nodata}
                                  className="img-fluid"
                                  alt="sectionbg"
                                />
                              </center>
                            </div>
                          ) : (
                            <>
                              <thead>
                                <tr>
                                  <th colSpan="2">IPO Name</th>
                                  <th>Offer Date</th>
                                  <th>Offer Price</th>
                                  <th>Lot Size</th>
                                  <th>GMP</th>
                                  <th colSpan="2">Est Listing</th>
                                </tr>
                              </thead>
                              <tbody>
                                {[
                                  ...(liveSMEIpos || []),
                                  ...(upcomingSMEIpos || []),
                                ].map((ipo, index) => {
                                  const toPrice = parseFloat(ipo.toPrice) || 0;
                                  const GMP = parseFloat(ipo.GMP) || 0;
                                  const estListing = toPrice + GMP;
                                  const percentage = (
                                    (GMP * 100) /
                                    toPrice
                                  ).toFixed(2);

                                  let estListingClass = "est_listing_neutral";
                                  if (estListing > ipo.toPrice) {
                                    estListingClass = "est_listing_green";
                                  } else if (estListing < ipo.toPrice) {
                                    estListingClass = "est_listing_red";
                                  }

                                  return (
                                    <tr key={ipo.id}>
                                      <td className="ipo-image">
                                        <img
                                          src={ipo.file}
                                          alt={ipo.companyName}
                                        />
                                      </td>
                                      <td className="ipo-name">
                                        <span
                                          className={`status ${ipo.IPOStatus.toLowerCase()}`}
                                        >
                                          {ipo?.IPOStatus ===
                                            "WaitingAllotment" &&
                                            "Waiting Allotment"}
                                          {ipo?.IPOStatus === "AllotmentOut" &&
                                            "Allotment Out"}
                                          {ipo?.IPOStatus ===
                                            "AllotmentToday" &&
                                            "Allotment Today"}
                                          {ipo?.IPOStatus === "Live" && "Live"}
                                          {ipo?.IPOStatus === "Upcoming" &&
                                            "Upcoming"}
                                          {ipo?.IPOStatus === "Listed" &&
                                            "Listed"}
                                        </span>
                                        <Link
                                          className="ipo_name btn btn-primary"
                                          to={`/ipo/${ipo.slug}`}
                                        >
                                          <strong>{ipo.companyName}</strong>
                                        </Link>
                                      </td>
                                      <td className="ipo-date">
                                        {formatDateRange(
                                          ipo.IPOOpenDate,
                                          ipo.IPOCloseDate
                                        )}
                                      </td>
                                      <td className="ipo-price">
                                        {ipo?.fromPrice && ipo?.toPrice
                                          ? `₹${ipo.fromPrice} to ₹${ipo.toPrice}`
                                          : "N/A"}
                                      </td>
                                      <td className="ipo-lot">
                                        {ipo?.lotSize
                                          ? `${ipo.lotSize} Shares`
                                          : "N/A"}
                                      </td>
                                      <td
                                        className={`ipo-gmp ${
                                          ipo.GMP < 0
                                            ? "false"
                                            : ipo.GMP > 0
                                            ? "true"
                                            : "neutral"
                                        }`}
                                      >
                                        {isNaN(ipo.GMP) ? "-" : `₹${ipo.GMP}`}
                                      </td>
                                      <td
                                        className={`ipo-est-listing ${estListingClass}`}
                                      >
                                        {estListing
                                          ? `₹${estListing} (${percentage}%)`
                                          : "-"}
                                      </td>
                                      <td className="ipo-link">
                                        <Link
                                          className="btn btn-primary"
                                          to={`/ipo/${ipo.slug}`}
                                        >
                                          View
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </>
                          )}
                        </Table>
                      )}
                    </div>
                    <div className="d-block d-md-none">
                      {loading ? (
                        <div className="text-center">
                          <Loader />
                        </div>
                      ) : (
                        <>
                          {liveMainLineIpos?.length === 0 &&
                          upcomingMainLineIpos?.length === 0 ? (
                            <div>
                              <center>
                                <img
                                  src={nodata}
                                  class="img-fluid"
                                  alt="sectionbg"
                                />
                              </center>
                            </div>
                          ) : (
                            <>
                              <div className="mobile_ipo text-start">
                                {[
                                  ...(liveSMEIpos || []),
                                  ...(upcomingSMEIpos || []),
                                ].map((ipo) => {
                                  const toPrice = parseFloat(ipo.toPrice) || 0;
                                  const GMP = parseFloat(ipo.GMP) || 0;
                                  const estListing = toPrice + GMP;
                                  const percentage = (
                                    (GMP * 100) /
                                    toPrice
                                  ).toFixed(2);

                                  let estListingClass = "est_listing_neutral";
                                  if (estListing > toPrice) {
                                    estListingClass = "est_listing_green";
                                  } else if (estListing < toPrice) {
                                    estListingClass = "est_listing_red";
                                  }

                                  return (
                                    <Link
                                      className="mobile_card"
                                      to={`/ipo/${ipo.slug}`}
                                      key={ipo.id}
                                    >
                                      <span
                                        className={`status ${ipo.IPOStatus.toLowerCase()}`}
                                      >
                                        {ipo?.IPOStatus ===
                                          "WaitingAllotment" &&
                                          "Waiting Allotment"}
                                        {ipo?.IPOStatus === "AllotmentOut" &&
                                          "Allotment Out"}
                                        {ipo?.IPOStatus === "AllotmentToday" &&
                                          "Allotment Today"}
                                        {ipo?.IPOStatus === "Live" && "Live"}
                                        {ipo?.IPOStatus === "Upcoming" &&
                                          "Upcoming"}
                                        {ipo?.IPOStatus === "Listed" &&
                                          "Listed"}
                                      </span>
                                      <Row className="align-items-center">
                                        <Col xs={4}>
                                          <img
                                            src={ipo.file}
                                            alt={ipo.companyName}
                                            className="img-fluid ipo_company_logo"
                                          />
                                        </Col>
                                        <Col xs={8}>
                                          <h6 className="mb-0 ipo_companyName">
                                            {ipo.companyName}
                                          </h6>
                                        </Col>
                                      </Row>
                                      <Row className="top_border">
                                        <Col xs={8} className="border_right">
                                          <p className="small card_title mb-0">
                                            Offer Date
                                          </p>
                                          <p className="mb-0 card_info fw-medium">
                                            {formatDateRange(
                                              ipo.IPOOpenDate,
                                              ipo.IPOCloseDate
                                            )}
                                          </p>
                                        </Col>
                                        <Col xs={4}>
                                          <p className="small card_title mb-0">
                                            Offer Price
                                          </p>
                                          <p className="mb-0 card_info fw-medium">
                                            {ipo?.fromPrice && ipo?.toPrice
                                              ? `₹${ipo.fromPrice} to ₹${ipo.toPrice}`
                                              : "N/A"}
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row className="top_border">
                                        <Col xs={4} className="border_right">
                                          <p className="small card_title mb-0">
                                            Lot Size
                                          </p>
                                          <p className="mb-0 card_info fw-medium">
                                            {ipo?.lotSize
                                              ? `${ipo.lotSize} Shares`
                                              : "N/A"}
                                          </p>
                                        </Col>
                                        <Col xs={3} className="border_right">
                                          <p className="small card_title mb-0">
                                            GMP
                                          </p>
                                          <p
                                            className={`mb-0 ipo-gmp ${
                                              ipo.GMP < 0
                                                ? "false"
                                                : ipo.GMP > 0
                                                ? "true"
                                                : "neutral"
                                            }`}
                                          >
                                            {isNaN(ipo.GMP)
                                              ? "-"
                                              : `₹${ipo.GMP}`}
                                          </p>
                                        </Col>
                                        <Col xs={5}>
                                          <p className="small card_title mb-0">
                                            Est Listing
                                          </p>
                                          <p
                                            className={`mb-0 ipo-est-listing ${estListingClass}`}
                                          >
                                            {estListing
                                              ? `₹${estListing} (${percentage}%)`
                                              : "-"}
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row className="top_border">
                                        <Col>
                                          <p className="ipo_card_link mb-0">
                                            View More Information
                                          </p>
                                        </Col>
                                      </Row>
                                    </Link>
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default GMP;
