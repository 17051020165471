import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Modal,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import IPO_Dekho_Feture_img from "../asset/images/IPO_Dekho_Feture_img.jpg";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const AccountDelete = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [showModal, setShowModal] = useState(false); // State for modal visibility

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleDeleteClick = (e) => {
    e.preventDefault();

    // Validation checks
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    } else {
      setEmailError("");
    }

    if (!recaptchaValue) {
      alert("Please complete the reCAPTCHA challenge.");
      return;
    }

    // Show confirmation modal
    setShowModal(true);
  };

  const confirmDelete = async () => {
    setIsSubmitted(true);
    await axios({
      method: "POST",
      url: "https://app.ipodekho.com/createContact",
      data: {
        FirstName: "Delete",
        lastName: "Delete",
        email: email,
        phone: "000",
        subject: "Delete Account",
        message: "Delete Account",
      },
      headers: {
        Accept: "application/json",
      },
    })
      .then(function (response) {
        console.log("response===>Delete Account", response.data.msg);
        if (response.data.msg === "Contact Created Successfully") {
          setEmail("");
          setSuccessMessage("Your account deletion request was successful.");
          setRecaptchaValue(null);
        }
      })
      .catch(function (error) {
        console.log("error in delete account", error);
      })
      .finally(() => {
        setIsSubmitted(false);
        setShowModal(false); // Hide the modal after processing
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEmail(""); // Clear the email input when the modal is closed
  };

  return (
    <>
      <Helmet>
        <title>Delete Your Account - IPO DEKHO</title>
        <meta
          name="description"
          content="Stay informed with IPO Dekho, your ultimate source for the latest Mainline and SME IPOs."
        />
        <meta
          property="og:url"
          content="https://ipodekho.com/delete-your-account"
        />
        <meta property="og:image" content={IPO_Dekho_Feture_img} />
        <link rel="canonical" href="https://ipodekho.com/delete-your-account" />
      </Helmet>
      <section className="banner_section">
        <Container>
          <Row>
            <Col lg={{ span: 6, offset: 3 }}>
              <h1 className="text-center fw-bold mb-5">Delete Your Account</h1>
              <p className="text-center">
                <b>Note:</b>The action of deleting all data that cannot be
                recovered is a critical and irreversible process with
                significant consequences. Once you confirm your decision to
                proceed with the deletion, all data associated with the action
                will be permanently eliminated beyond any possibility of
                retrieval or restoration.
              </p>

              {successMessage && (
                <Alert variant="success" className="text-center">
                  {successMessage}
                </Alert>
              )}

              <Form onSubmit={handleDeleteClick}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Enter Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    required
                    className="deleteaccount_field"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    isInvalid={!!emailError}
                  />
                  {emailError && (
                    <Form.Control.Feedback type="invalid">
                      {emailError}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <ReCAPTCHA
                  sitekey="6LdgT04qAAAAALWC6iHWdvHEMg4pT4tTAbyyDZas"
                  onChange={(value) => setRecaptchaValue(value)}
                />

                <Button
                  className="deleteaccount_btn"
                  type="submit"
                  disabled={
                    isSubmitted || email.length === 0 || !recaptchaValue
                  }
                >
                  {isSubmitted ? "Processing..." : "Delete Account"}
                </Button>
              </Form>

              {/* Confirmation Modal */}
              <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete your account? This action is
                  irreversible.
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseModal}>
                    No, Cancel
                  </Button>
                  <Button variant="danger" onClick={confirmDelete}>
                    Yes, Delete
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AccountDelete;
