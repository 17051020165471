
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import NavbarComponent from "./Component/NavbarComponent";
import FooterComponent from "./Component/FooterComponent";
import DownloadPopup from "./Component/DownloadPopup";
import routes from "./routes";

import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import AOS from "aos";
import "./asset/css/icofont.min.css";
import "./asset/css/style.css";
import "./asset/css/responsive.css";
import "./asset/js/main.js";
import favicon from "../src/asset/images/favicon.png";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Router>
      <div>
        <Helmet>
          <link rel="shortcut icon" href={favicon} type="image/x-icon"></link>
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9374105485162544"
            crossorigin="anonymous"
          ></script>
        </Helmet>
        <NavbarComponent />
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Routes>
        <FooterComponent />
      </div>
      <DownloadPopup />
    </Router>
  );
}

export default App;
