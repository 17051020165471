import HomePage from './Page/HomePage';
import GMP from './Page/GMP';
import IPODetail from './Page/IPODetail';
import PrivacyPolicyPage from './Page/PrivacyPolicyPage';
import TermsConditionsPage from './Page/TermsConditionsPage';
import MainboardLive from './Page/MainboardLive';
import SMELive from './Page/SMELive';
import MainboardUpcoming from './Page/MainboardUpcoming';
import SMEUpcoming from './Page/SMEUpcoming';
import MainboardListed from './Page/MainboardListed';
import SMEListed from './Page/SMEListed';
import UpcomingIpos from './Page/UpcomingIpos';
import FAQs from './Page/FAQsPage';
import Iposdvdv from './Page/IPOPage';
import AccountDelete from './Page/AccountDelete';


const routes = [
  { path: '/', component: HomePage },
  { path: '/gmp', component: GMP },
  { path: '/ipo/:slug', component: IPODetail },
  { path: '/privacy-policy', component: PrivacyPolicyPage },
  { path: '/terms-conditions', component: TermsConditionsPage },
  { path: '/mainboard-live-ipos', component: MainboardLive },
  { path: '/sme-live-ipos', component: SMELive },
  { path: '/mainboard-upcoming-ipos', component: MainboardUpcoming },
  { path: '/sme-upcoming-ipos', component: SMEUpcoming },
  { path: '/mainboard-listed-ipos', component: MainboardListed },
  { path: '/sme-listed-ipos', component: SMEListed },
  { path: '/upcoming-ipos', component: UpcomingIpos },
  { path: '/faqs', component: FAQs },
  { path: '/ipos', component: Iposdvdv },
  { path: '/delete-your-account', component: AccountDelete },
];

export default routes;
