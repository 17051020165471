import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css'; // Import styles
import avtarTestimonial from '../asset/images/avtar_testimonial.png';

const testimonials = [
  {
    name: 'Dhaval Vachhani',
    review: `I am glad to hear that you are finding the IPO tracking app to be wonderful and helpful in keeping track of all the IPOs. The user-friendly interface and timely notifications are making the experience even better. It's great to hear that you can easily get all the details about IPOs within seconds without having to browse on Google. The app provides proper details about the company, which is essential for every investor to know before investing or applying for any IPO.`,
    image: require('../asset/images/testimonial_user1.png'),
  },
  {
    name: 'Pradhumansinh Zala',
    review: `Best app for checking IPO Details, informative app with chat features.`,
    image: require('../asset/images/testimonial_user2.png'),
  },
  {
    name: 'Nisarg Parekh',
    review: `This app provide real-time updates and alerts on upcoming IPOs, this can be especially helpful for investors who are looking to stay informed and make informed investment decisions.`,
    image: require('../asset/images/testimonial_user3.png'),
  },
];

const TestimonialSection = () => {
  const options = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    smartSpeed: 2500,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  return (
    <section className="row_am testimonial_section" id="testimonials">
      <div className="container">
        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
          <h2>What our <span>customer say</span></h2>
          <p>What customer have to say about their experience with us.</p>
        </div>
        <div className="testimonial_block" data-aos="fade-in" data-aos-duration="1500">
          <OwlCarousel options={options}>
            {testimonials.map((testimonial, index) => (
              <div className="item" key={index}>
                <div className="testimonial_slide_box">
                  <div className="rating">
                    <span><i className="icofont-star"></i></span>
                    <span><i className="icofont-star"></i></span>
                    <span><i className="icofont-star"></i></span>
                    <span><i className="icofont-star"></i></span>
                    <span><i className="icofont-star"></i></span>
                  </div>
                  <p className="review">{testimonial.review}</p>
                  <div className="testimonial_img">
                    <img src={testimonial.image} alt={testimonial.name} />
                  </div>
                  <h3>{testimonial.name}</h3>
                </div>
              </div>
            ))}
          </OwlCarousel>
          <div className="total_review">
            <div className="rating">
              <span><i className="icofont-star"></i></span>
              <span><i className="icofont-star"></i></span>
              <span><i className="icofont-star"></i></span>
              <span><i className="icofont-star"></i></span>
              <span><i className="icofont-star"></i></span>
              <p>4.6 / 5.0</p>
            </div>
            <h3>184</h3>
          </div>
          <div className="avtar_faces">
            <img src={avtarTestimonial} alt="Testimonials" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
