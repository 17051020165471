// Loader.js
import React from 'react';
import Lottie from 'lottie-react';
import loaderAnimation from '../asset/images/loader.json'; // Adjust the path to your JSON file

const Loader = () => {
  return (
    <div className="loader-container">
      <Lottie 
        animationData={loaderAnimation} 
        loop={true} 
        style={{ height: '250px', width: '100%' }} 
      />
    </div>
  );
};

export default Loader;
