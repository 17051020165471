import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import appstore from "../asset/images/appstore.png";
import googleplay from "../asset/images/googleplay.png";
import downloadscreen01 from "../asset/images/download-screen01.png";
import downloadscreen02 from "../asset/images/download-screen02.png";

const FreeAppSection = () => {
  return (
    <section className="row_am free_app_section" id="getstarted">
      <Container>
        <div
          className="free_app_inner"
          data-aos="fade-in"
          data-aos-duration="1500"
          data-aos-delay="100"
        >
          <Row>
            {/* Content */}
            <Col md={6}>
              <div className="free_text">
                <div className="section_title">
                  <h2>Let's download for free from the Apple App Store and Google Play Store.</h2>
                  <p>
                    Download for free from the Apple App Store and Google Play
                    Store. Enjoy seamless access and enhance your experience
                    with our user-friendly app available on both major
                    platforms.
                  </p>
                </div>
                <ul className="app_btn">
                  <li>
                    <a
                      href="https://apps.apple.com/in/app/ipodekho/id6446677905"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={appstore} alt="App Store" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.ipodekho"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={googleplay} alt="Google Play" />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            {/* Images */}
            <Col md={6}>
              <div className="free_img">
                <img src={downloadscreen01} alt="Download Screen 1" />
                <img
                  className="mobile_mockup"
                  src={downloadscreen02}
                  alt="Download Screen 2"
                />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default FreeAppSection;
