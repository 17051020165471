// src/Page/SMELive.js

import React, { useState, useEffect } from "react";
import { Table, Row, Col } from "react-bootstrap";
import axios from "axios";
import { Helmet } from "react-helmet";
import Loader from "../Component/Loader";
import nodata from "../asset/images/ListingSoon.webp";
import { Link } from "react-router-dom";
import SMELive_Fetureimg from "../asset/images/SMELive_Fetureimg.jpg";


const SMELive = () => {
  const [loading, setLoading] = useState(true);
  const [liveSMEIpos, setLiveSMEIpos] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        const smeLiveResponse = await axios.post(
          "https://app.ipodekho.com/GetMainLineIpo",
          {
            CategoryForIPOS: "SmeIPO",
            type: "Live",
          }
        );
        setLiveSMEIpos(smeLiveResponse.data.liveIpo);
        setLoading(false); // Stop loading
      } catch (error) {
        console.error("Error fetching IPO data:", error);
        setLoading(false); // Stop loading even if there is an error
      }
    };
    fetchData();
  }, []);
  function formatDateRange(openDate, closeDate) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const open = new Date(openDate).toLocaleDateString(undefined, options);
    const close = new Date(closeDate).toLocaleDateString(undefined, options);
    return (
      <>
        {open} to <br /> {close}
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>SME Live IPOs - IPO DEKHO</title>
        <meta name="description" content="Welcome to the SME Live IPOs section on IPO DEKHO, your dedicated resource for the latest updates and detailed information on Small and Medium Enterprise (SME) initial public offerings. This page is designed to keep you informed about active SME IPOs, helping you explore emerging opportunities in the market." />
        <meta property="og:url" content="https://ipodekho.com/sme-live-ipos" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="SME Live IPOs - IPO DEKHO" />
        <meta property="og:description" content="Welcome to the SME Live IPOs section on IPO DEKHO, your dedicated resource for the latest updates and detailed information on Small and Medium Enterprise (SME) initial public offerings. This page is designed to keep you informed about active SME IPOs, helping you explore emerging opportunities in the market." />
        <meta property="og:image" content={SMELive_Fetureimg} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="https://ipodekho.com/sme-live-ipos" />
        <meta property="twitter:url" content="https://ipodekho.com/sme-live-ipos" />
        <meta name="twitter:title" content="SME Live IPOs - IPO DEKHO" />
        <meta name="twitter:description" content="Welcome to the SME Live IPOs section on IPO DEKHO, your dedicated resource for the latest updates and detailed information on Small and Medium Enterprise (SME) initial public offerings. This page is designed to keep you informed about active SME IPOs, helping you explore emerging opportunities in the market." />
        <meta name="twitter:image" content={SMELive_Fetureimg} />

        <link rel="canonical" href="https://ipodekho.com/sme-live-ipos" />
        
      </Helmet>
      <section className="ipo-detail">
        <div className="container">
          <div className="section_title">
            <h2>
              <span>SME Live </span> IPOs
            </h2>
            <p>
              SME upcoming IPOs involve small and medium-sized enterprises
              preparing to list shares on stock exchanges. Investors eagerly
              watch these IPOs for high-growth potential. SMEs often introduce
              innovative products, offering a chance to invest early. These IPOs
              provide opportunities for substantial returns despite higher risks
              compared to larger companies.
            </p>
          </div>
          <div className="ipo_table_div">
            <div className="d-none d-md-block">
              {loading ? (
                <div className="text-center">
                  <Loader />
                </div>
              ) : (
                <Table bordered className="ipo_table">
                  {liveSMEIpos.length === 0 ? (
                    <div>
                      <center>
                        <img src={nodata} class="img-fluid" alt="sectionbg" />
                      </center>
                    </div>
                  ) : (
                    <>
                      <thead>
                        <tr>
                          <th colSpan="2">IPO Name</th>
                          <th>Offer Date</th>
                          <th>Offer Price</th>
                          <th>Lot Size</th>
                          <th>Subs</th>
                          <th colSpan="2">GMP</th>
                        </tr>
                      </thead>
                      <tbody>
                        {liveSMEIpos.map((ipo, index) => (
                          <tr key={ipo.id}>
                            <td className="ipo-image">
                              <img src={ipo.file} alt={ipo.companyName} />
                            </td>
                            <td className="ipo-name">
                              <span
                                className={`status ${ipo.IPOStatus.toLowerCase()}`}
                              >
                                {ipo?.IPOStatus === "WaitingAllotment"
                                  ? "Waiting Allotment"
                                  : null}
                                {ipo?.IPOStatus === "AllotmentOut"
                                  ? " Allotment Out"
                                  : null}
                                {ipo?.IPOStatus === "AllotmentToday"
                                  ? "Allotment Today"
                                  : null}
                                {ipo?.IPOStatus === "Live" ? "Live" : null}
                                {ipo?.IPOStatus === "Upcoming"
                                  ? "Upcoming"
                                  : null}
                                {ipo?.IPOStatus === "Listed" ? "Listed" : null}
                              </span>
                              <Link
                                className="ipo_name btn btn-primary"
                                to={`/ipo/${ipo.slug}`}
                              >
                                <strong>{ipo.companyName}</strong>
                              </Link>
                            </td>
                            <td className="ipo-date">
                              {formatDateRange(
                                ipo.IPOOpenDate,
                                ipo.IPOCloseDate
                              )}
                            </td>
                            <td className="ipo-price">
                              {ipo?.fromPrice && ipo?.toPrice
    ? `₹${ipo.fromPrice} to ₹${ipo.toPrice}`
    : "N/A"}
                            </td>
                            <td className="ipo-lot">{ipo?.lotSize ? `${ipo.lotSize} Shares` : "N/A"}</td>
                            <td className="ipo-subs">{ipo?.total ? `${ipo.total} x` : "N/A"}</td>
                            <td
                              className={`ipo-gmp ${
                                ipo.GMP < 0
                                  ? "false"
                                  : ipo.GMP > 0
                                  ? "true"
                                  : "neutral"
                              }`}
                            >
                              {isNaN(ipo.GMP) ? "-" : `₹${ipo.GMP}`}{" "}
                              {isNaN(ipo.GMP) || isNaN(ipo.toPrice)
                                ? ""
                                : `(${((ipo.GMP * 100) / ipo.toPrice).toFixed(
                                    2
                                  )}%)`}
                            </td>
                            <td className="ipo-link">
                              <Link className="btn btn-primary" to={`/ipo/${ipo.slug}`}>
                                            View
                                          </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  )}
                </Table>
              )}
            </div>
            <div className="d-block d-md-none">
              {loading ? (
                <div className="text-center">
                  <Loader />
                </div>
              ) : (
                <>
                  {liveSMEIpos.length === 0 ? (
                    <div>
                      <center>
                        <img src={nodata} class="img-fluid" alt="sectionbg" />
                      </center>
                    </div>
                  ) : (
                    <>
                      <div className="mobile_ipo text-start">
                        {liveSMEIpos.map((ipo, index) => (
                          <Link
                            className="mobile_card"
                            to={`/ipo/${ipo.slug}`}
                            key={ipo.id}
                          >
                            <span
                              className={`status ${ipo.IPOStatus.toLowerCase()}`}
                            >
                              {ipo?.IPOStatus === "WaitingAllotment"
                                ? "Waiting Allotment"
                                : null}
                              {ipo?.IPOStatus === "AllotmentOut"
                                ? " Allotment Out"
                                : null}
                              {ipo?.IPOStatus === "AllotmentToday"
                                ? "Allotment Today"
                                : null}
                              {ipo?.IPOStatus === "Live" ? "Live" : null}
                              {ipo?.IPOStatus === "Upcoming"
                                ? "Upcoming"
                                : null}
                              {ipo?.IPOStatus === "Listed" ? "Listed" : null}
                            </span>
                            <Row className="align-items-center">
                              <Col xs={4}>
                                <img
                                  src={ipo.file}
                                  alt={ipo.companyName}
                                  className="img-fluid ipo_company_logo"
                                />
                              </Col>
                              <Col xs={8}>
                                <h6 className="mb-0 ipo_companyName">
                                  {ipo.companyName}
                                </h6>
                              </Col>
                            </Row>
                            <Row className="top_border">
                              <Col xs={8} className="border_right">
                                <p className="small card_title mb-0">
                                  Offer Date
                                </p>
                                <p className="mb-0 card_info fw-medium">
                                  {formatDateRange(
                                    ipo.IPOOpenDate,
                                    ipo.IPOCloseDate
                                  )}
                                </p>
                              </Col>
                              <Col xs={4}>
                                <p className="small card_title mb-0">
                                  Offer Price
                                </p>
                                <p className="mb-0 card_info fw-medium">
                                  {ipo?.fromPrice && ipo?.toPrice
    ? `₹${ipo.fromPrice} to ₹${ipo.toPrice}`
    : "N/A"}
                                </p>
                              </Col>
                            </Row>
                            <Row className="top_border">
                              <Col xs={4} className="border_right">
                                <p className="small card_title mb-0">
                                  Lot Size
                                </p>
                                <p className="mb-0 card_info fw-medium">
                                  {ipo?.lotSize ? `${ipo.lotSize} Shares` : "N/A"}
                                </p>
                              </Col>
                              <Col xs={3} className="border_right">
                                <p className="small card_title mb-0">Subs</p>
                                <p className="mb-0 card_info fw-medium">
                                  {ipo?.total ? `${ipo.total} x` : "N/A"}
                                </p>
                              </Col>
                              <Col xs={5}>
                                <p className="small card_title mb-0">
                                  Premium (GMP)
                                </p>
                                <p
                                  className={`mb-0 ipo-gmp ${
                                    ipo.GMP < 0
                                      ? "false"
                                      : ipo.GMP > 0
                                      ? "true"
                                      : "neutral"
                                  }`}
                                >
                                  {isNaN(ipo.GMP) ? "-" : `₹${ipo.GMP}`}{" "}
                                  {isNaN(ipo.GMP) || isNaN(ipo.toPrice)
                                    ? ""
                                    : `(${(
                                        (ipo.GMP * 100) /
                                        ipo.toPrice
                                      ).toFixed(2)}%)`}
                                </p>
                              </Col>
                            </Row>
                            <Row className="top_border">
                              <Col>
                                <p className="ipo_card_link mb-0">
                                  View More Information
                                </p>
                              </Col>
                            </Row>
                          </Link>
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SMELive;
