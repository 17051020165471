import React, { useState } from "react";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import logo from "../asset/images/logo.svg";
import appstore from "../asset/images/appstore.png";
import googleplay from "../asset/images/googleplay.png";
import { useLocation } from "react-router-dom";
import gmp from "../asset/images/gmp.gif";

const MyNavbar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const location = useLocation(); // Get the current location

  const isActive = (path) => location.pathname === path;

  return (
    <>
      <Navbar expand="lg" fixed="top">
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle onClick={handleShow} className="d-lg-none" />
          <Navbar.Collapse className="justify-content-end d-none d-lg-flex">
            <Nav className="ml-auto">
              <Nav.Link
                href="/gmp"
                className={isActive("/gmp") ? "active_link" : ""}
              >
                Live IPO GMP <img src={gmp} alt="gmp" className="gmp_icon" />
              </Nav.Link>
              <NavDropdown title="Mainboard IPOs" id="mainboard-dropdown">
                <NavDropdown.Item
                  href="/mainboard-live-ipos"
                  className={
                    isActive("/mainboard-live-ipos") ? "active_link" : ""
                  }
                >
                  Live IPOs
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/mainboard-upcoming-ipos"
                  className={
                    isActive("/mainboard-upcoming-ipos") ? "active_link" : ""
                  }
                >
                  Upcoming IPOs
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/mainboard-listed-ipos"
                  className={
                    isActive("/mainboard-listed-ipos") ? "active_link" : ""
                  }
                >
                  Listed IPOs
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="SME IPOs" id="sme-dropdown">
                <NavDropdown.Item
                  href="/sme-live-ipos"
                  className={isActive("/sme-live-ipos") ? "active_link" : ""}
                >
                  Live IPOs
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/sme-upcoming-ipos"
                  className={
                    isActive("/sme-upcoming-ipos") ? "active_link" : ""
                  }
                >
                  Upcoming IPOs
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/sme-listed-ipos"
                  className={isActive("/sme-listed-ipos") ? "active_link" : ""}
                >
                  Listed IPOs
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                href="/upcoming-ipos"
                className={isActive("/upcoming-ipos") ? "active_link" : ""}
              >
                Upcoming IPOs
              </Nav.Link>
              <Nav.Link
                href="/faqs"
                className={`border_bottom ${
                  isActive("/faqs") ? "active_link" : ""
                }`}
              >
                FAQs
              </Nav.Link>
              <div className="header_button">
                <Nav.Link
                  href="https://apps.apple.com/in/app/ipodekho/id6446677905"
                  target="_blank"
                >
                  <img src={appstore} alt="appstore" className="icon_nav" />
                </Nav.Link>
                <Nav.Link
                  href="https://play.google.com/store/apps/details?id=com.ipodekho"
                  target="_blank"
                >
                  <img src={googleplay} alt="googleplay" className="icon_nav" />
                </Nav.Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <button
            type="button"
            class="navbar-toggler navbar-toggler-mobile"
            onClick={handleClose}
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/gmp">Live IPO GMP <img src={gmp} alt="gmp" className="gmp_icon" /></Nav.Link>
            <NavDropdown
              title="Mainboard IPOs"
              id="mainboard-dropdown-offcanvas"
            >
              <NavDropdown.Item href="/mainboard-live-ipos">
                Live IPOs
              </NavDropdown.Item>
              <NavDropdown.Item href="/mainboard-upcoming-ipos">
                Upcoming IPOs
              </NavDropdown.Item>
              <NavDropdown.Item href="/mainboard-listed-ipos">
                Listed IPOs
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="SME IPOs" id="sme-dropdown-offcanvas">
              <NavDropdown.Item href="/sme-live-ipos">
                Live IPOs
              </NavDropdown.Item>
              <NavDropdown.Item href="/sme-upcoming-ipos">
                Upcoming IPOs
              </NavDropdown.Item>
              <NavDropdown.Item href="/sme-listed-ipos">
                Listed IPOs
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/upcoming-ipos">Upcoming IPOs</Nav.Link>
            <Nav.Link className="border_bottom" href="/faqs">
              FAQs
            </Nav.Link>
            <div className="header_button">
              <Nav.Link
                href="https://apps.apple.com/in/app/ipodekho/id6446677905"
                target="_blank"
              >
                <img src={appstore} alt="appstore" className="icon_nav" />
              </Nav.Link>
              <Nav.Link
                href="https://play.google.com/store/apps/details?id=com.ipodekho"
                target="_blank"
              >
                <img src={googleplay} alt="googleplay" className="icon_nav" />
              </Nav.Link>
            </div>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default MyNavbar;
