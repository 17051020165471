import $ from 'jquery';
import throttle from 'lodash/throttle';

// Throttle the scroll event to improve performance
window.addEventListener('scroll', throttle(handleScroll, 200));

function handleScroll() {
  var element = document.querySelector('#counter');
  if (!element) return;

  var position = element.getBoundingClientRect();
  var fullyVisible = position.top >= 0 && position.bottom <= window.innerHeight;
  var partiallyVisible = position.top < window.innerHeight && position.bottom >= 0;

  if (fullyVisible) {
    animateCounters();
  } else if (!partiallyVisible) {
    resetCounters();
  }
}

function animateCounters() {
  $('.counter-value').each(function() {
    var $this = $(this);
    var countTo = $this.attr('data-count');
    $this.stop(true); // Stop any ongoing animations
    $({
      countNum: $this.text()
    }).animate({
        countNum: countTo
      }, { 
        duration: 2000,
        easing: 'swing',
        step: function() {
          $this.text(Math.floor(this.countNum));
        },
        complete: function() {
          $this.text(this.countNum);
        }
      });
  });
}

function resetCounters() {
  $('.counter-value').each(function() {
    var $this = $(this);
    $this.stop(true); // Stop any ongoing animations
    $({
      countNum: $this.text()
    }).animate({
        countNum: 0
      }, {
        duration: 100,
        easing: 'swing',
        step: function() {
          $this.text(Math.floor(this.countNum));
        },
        complete: function() {
          $this.text(this.countNum);
        }
      });
  });
}
