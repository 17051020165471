import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import IPO_Dekho_Feture_img from "../asset/images/IPO_Dekho_Feture_img.jpg";

const FAQsPage = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [faqs, setFaqs] = useState([]);
  useEffect(() => {
    fetchFAQ();
  }, []);
  const fetchFAQ = async () => {
    try {
      const response = await fetch("https://app.ipodekho.com/GetAllFaqs");
      const data = await response.json();
      setFaqs(data.data.faq);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const renderFAQ = () => {
    return faqs.map((faq, index) => (
      <div className="card" key={index}>
        <div className="card-header" id={`faq${index + 1}`}>
          <h2 className="mb-0">
            <button
              type="button"
              className={`btn btn-link ${
                index === activeIndex ? "active" : "collapsed"
              }`}
              onClick={() => toggleFAQ(index)}
            >
              <i className="icon_faq icofont-plus"></i> {faq.Que}
            </button>
          </h2>
        </div>
        <div
          id={`faq${index + 1}collapse`}
          className={`collapse ${index === activeIndex ? "show" : ""}`}
          aria-labelledby={`faq${index + 1}`}
          data-parent="#accordionExample"
        >
          <div className="card-body">
            <p dangerouslySetInnerHTML={{ __html: faq.ans }} />
          </div>
        </div>
      </div>
    ));
  };
  const toggleFAQ = (index) => {
    if (activeIndex === index) {
      // If the clicked FAQ item is already open, close it
      setActiveIndex(null);
    } else {
      // Otherwise, open the clicked FAQ item
      setActiveIndex(index);
    }
  };
  return (
    <>
    <Helmet>
        <title>FAQs - IPO DEKHO</title>
        <meta name="description" content="Discover comprehensive answers to your questions about IPO DEKHO. Learn about our services, how to use the platform, benefits of tracking IPOs, and more. Get the insights you need to make informed investment decisions with IPO DEKHO." />
        <meta property="og:url" content="https://ipodekho.com/faqs" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="FAQs - IPO DEKHO" />
        <meta property="og:description" content="Discover comprehensive answers to your questions about IPO DEKHO. Learn about our services, how to use the platform, benefits of tracking IPOs, and more. Get the insights you need to make informed investment decisions with IPO DEKHO." />
        <meta property="og:image" content={IPO_Dekho_Feture_img} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="https://ipodekho.com/faqs" />
        <meta property="twitter:url" content="https://ipodekho.com/faqs" />
        <meta name="twitter:title" content="FAQs - IPO DEKHO" />
        <meta name="twitter:description" content="Discover comprehensive answers to your questions about IPO DEKHO. Learn about our services, how to use the platform, benefits of tracking IPOs, and more. Get the insights you need to make informed investment decisions with IPO DEKHO." />
        <meta name="twitter:image" content={IPO_Dekho_Feture_img} />
      </Helmet>
      <section className="row_am faq_section faq_section_page" id="faqs">
        <div className="container">
          <div className="section_title">
            <h2>
              <span>FAQ</span> - Frequently Asked Questions
            </h2>
            <p>
              FAQs provide quick and easy answers to common inquiries,
              <br />
              reducing the need for additional customer support.
            </p>
          </div>
          <div className="faq_panel">
            <div className="accordion" id="accordionExample">
              {renderFAQ()}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQsPage;
