import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Table } from "react-bootstrap";

const IPOListPage = () => {
  const [ipoData, setIpoData] = useState([]);

  useEffect(() => {
    fetchIPOData();
  }, []);

  const fetchIPOData = async () => {
    try {
      const response = await axios.post(
        "https://app.ipodekho.com/GetMainLineIpo"
      );
      if (
        response.data.msg === "All IPOS" &&
        response.data.data &&
        response.data.data.MainLineIpo
      ) {
        // Sort IPOs by IPOOpenDate in descending order
        const sortedIpos = response.data.data.MainLineIpo.sort((a, b) =>
          new Date(b.IPOOpenDate).getTime() - new Date(a.IPOOpenDate).getTime()
        );
        setIpoData(sortedIpos);
      } else {
        throw new Error("Invalid data format");
      }
    } catch (error) {
      console.error("Error fetching IPO data:", error);
    }
  };

  return (
    <section className="ipo-detail">
      <Container>
        <Row>
          <Col>
            <h2>All IPOs</h2>
            <Table bordered>
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Company Name</th>
                  <th>Slug</th>
                  <th>ID</th>
                  <th>Category</th>
                  <th>Status</th>
                  <th>Open Date</th>
                </tr>
              </thead>
              <tbody>
                {ipoData.map((ipo, index) => (
                  <tr key={ipo.id}>
                    <td>{index + 1}</td>
                    <td>{ipo.companyName}</td>
                    <td>{ipo.slug}</td>
                    <td>{ipo.id}</td>
                    <td>{ipo.CategoryForIPOS}</td>
                    <td>{ipo.IPOStatus}</td>
                    <td>{new Date(ipo.IPOOpenDate).toLocaleDateString('en-GB')}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default IPOListPage;
