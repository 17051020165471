// DownloadPopup.js
import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { addDays } from "date-fns";
import appstore from "../asset/images/appstore.png";
import googleplay from "../asset/images/googleplay.png";
import popupimg from "../asset/images/popupimg.png";

const DownloadPopup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const lastClosed = localStorage.getItem("popupLastClosed");
    let nextOpenDate = new Date();

    if (lastClosed) {
      const lastClosedDate = new Date(lastClosed);
      nextOpenDate = addDays(lastClosedDate, 1);
      // console.log(
      //   `Next popup open date: ${format(nextOpenDate, "yyyy-MM-dd HH:mm:ss")}`
      // );
    } else {
      // console.log(
      //   "No previous close date found. Showing popup for the first time."
      // );
    }

    const showPopup = new Date() >= nextOpenDate;
    if (showPopup) {
      setTimeout(() => {
        setIsVisible(true);
      }, 5000);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem("popupLastClosed", new Date().toISOString());
    setIsVisible(false);
  };

  return (
    <Modal
      show={isVisible}
      onHide={handleClose}
      centered
      size="lg"
      className="custom-modal download_popup"
    >
      <Modal.Body>
        <Button
          variant="secondary"
          className="popup_close"
          onClick={handleClose}
        >
          X
        </Button>
        <Row className="align-items-center popup_row">
          <Col md={5} className="popup_img_col">
            <img src={popupimg} className="img-fluid popupimg" alt="popupimg" />
          </Col>
          <Col md={7} className="text-center popup_content_col">
            <h2 className="fw-bold">
              Get the best experience by downloading <span className="theme_color">IPO Dekho</span> app!
            </h2>
            <p>
              Experience exclusive GMP insights, breaking news updates, seamless
              messaging, and user-friendly interface. Receive timely
              notifications for an immersive IPO journey. Download now.
            </p>
            <div className="download-buttons d-flex justify-content-around">
              <a
                href="https://apps.apple.com/in/app/ipodekho/id6446677905"
                target="_blank"
                rel="noreferrer"
                onClick={handleClose}
              >
                <img src={appstore} alt="appstore" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.ipodekho"
                target="_blank"
                rel="noreferrer"
                onClick={handleClose}
              >
                <img src={googleplay} alt="googleplay" />
              </a>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default DownloadPopup;
