import React, { useEffect, useRef } from 'react';
import gototop from '../asset/images/go_top.png'

const ScrollToTop = () => {
  const toTopRef = useRef(null);

  useEffect(() => {
    const currentRef = toTopRef.current; // Store ref in a variable

    const handleScroll = () => {
      const topPos = window.scrollY;
      if (topPos > 750) {
        currentRef.style.opacity = "1";
      } else {
        currentRef.style.opacity = "0";
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div ref={toTopRef} onClick={scrollToTop} className="go_top">
      <span>
        <img src={gototop} alt="Go to top" />
      </span>
    </div>
  );
};

export default ScrollToTop;
