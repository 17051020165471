import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../asset/images/logo.png";
import ScrollToTop from "../Component/ScrollToTop"

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer>
        {/* section bg */}
        <div className="top_footer" id="contact">
          {/* container start */}
          <Container>
            {/* row start */}
            <Row>
              {/* footer link 1 */}
              <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                <div className="abt_side">
                  <div className="logo">
                    {" "}
                    <img src={logo} alt="logo" />
                  </div>
                  <ul>
                    <li>
                      <a href="mailto:info@ipodekho.com">info@ipodekho.com</a>
                    </li>
                  </ul>
                  <div>
                    <Link to="/privacy-policy" className="mx-2">
                      Privacy Policy
                    </Link>
                    <Link to="/terms-conditions" className="mx-2">
                      Terms Conditions
                    </Link>
                  </div>
                  <ul className="social_media justify-content-center">
                    <li>
                      <a
                        href="https://www.facebook.com/ipodekho"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/ipo.dekho/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/ipo-dekho/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            {/* row end */}
          </Container>
          {/* container end */}
        </div>
        <Container>
          {/* row start */}
          <Row>
            <Col md={12}>
              <p className="small text-center">
                No financial information published within this application,
                including but not limited to information regarding securities or
                IPOs, should be considered as advice to buy or sell securities
                or to invest in IPOs, or as a guide to doing so in any way
                whatsoever. All content published in this application is
                provided solely for educational and informational purposes, and
                under no circumstances should it be used for making investment
                decisions. We are not a SEBI registered analyst.Readers must
                consult a qualified financial advisor before making any actual
                investment decisions based on information published in this
                application. The information in this application is based on
                information available as of the date of publication, along with
                market perceptions, and may be subject to change without notice.
              </p>
            </Col>
          </Row>
        </Container>
        {/* last footer */}
        <div className="bottom_footer">
          {/* container start */}
          <Container>
            {/* row start */}
            <Row>
              <Col md={6}>
                <p>© Copyrights {currentYear}. All rights reserved.</p>
              </Col>
              <Col md={6}>
                <p className="developer_text">
                  Design & developed by{" "}
                  <a
                    href="https://cipherbrains.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CipherBrains Technologies
                  </a>
                </p>
              </Col>
            </Row>
            {/* row end */}
          </Container>
          {/* container end */}
        </div>
        {/* go top button */}
        <ScrollToTop />
      </footer>
      <div className="purple_backdrop"></div>
    </>
  );
};

export default Footer;
