import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css'; // import styles

import screen1 from '../asset/images/screen-1.png'; // Adjust the path accordingly
import screen2 from '../asset/images/screen-2.png';
import screen3 from '../asset/images/screen-3.png';
import screen4 from '../asset/images/screen-4.png';
import screen5 from '../asset/images/screen-5.png';

const InterfaceSection = () => {
  const options = {
    loop: true,
    margin: 10,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 2500,
    center: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };

  return (
    <section className="row_am interface_section">
      <div className="container-fluid">
        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
          <h2>Beautiful <span>interface</span></h2>
          <p>
            A beautiful interface is intuitive, visually appealing, and functional,<br />
            enhancing the user experience and making tasks easier to accomplish.
          </p>
        </div>
        <div className="screen_slider">
          <OwlCarousel options={options}>
            <div className="item">
              <div className="screen_frame_img">
                <img src={screen1} alt="Screen 1" />
              </div>
            </div>
            <div className="item">
              <div className="screen_frame_img">
                <img src={screen2} alt="Screen 2" />
              </div>
            </div>
            <div className="item">
              <div className="screen_frame_img">
                <img src={screen3} alt="Screen 3" />
              </div>
            </div>
            <div className="item">
              <div className="screen_frame_img">
                <img src={screen4} alt="Screen 4" />
              </div>
            </div>
            <div className="item">
              <div className="screen_frame_img">
                <img src={screen5} alt="Screen 5" />
              </div>
            </div>
            <div className="item">
              <div className="screen_frame_img">
                <img src={screen3} alt="Screen 3" />
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
};

export default InterfaceSection;
