import React from 'react';

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
  const maxPageButtons = 6; // Number of page buttons to display around the current page
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Generate page numbers with breaks
  const pageNumbers = [];
  if (totalPages <= maxPageButtons) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    const leftSide = Math.floor(maxPageButtons / 2);
    const rightSide = maxPageButtons - leftSide - 1;

    if (currentPage <= leftSide) {
      for (let i = 1; i <= maxPageButtons; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push("...", totalPages);
    } else if (currentPage + rightSide >= totalPages) {
      pageNumbers.push(1, "...");
      for (let i = totalPages - maxPageButtons + 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1, "...");
      for (let i = currentPage - leftSide; i <= currentPage + rightSide; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push("...", totalPages);
    }
  }

  return (
    <div className="pagination ipo_pagination">
      {pageNumbers.map((number, index) => (
        <button
          key={index}
          onClick={() => number !== "..." && paginate(number)}
          className={`page-item ${currentPage === number ? 'active' : ''}`}
          disabled={number === "..."}
        >
          {number}
        </button>
      ))}
    </div>
  );
};

export default Pagination;
