// src/Page/PrivacyPolicyPage.js

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import IPO_Dekho_Feture_img from "../asset/images/IPO_Dekho_Feture_img.jpg";

const PrivacyPolicyPage = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - IPO DEKHO</title>
        <meta
          name="description"
          content="Stay informed with IPO Dekho, your ultimate source for the latest Mainline and SME IPOs. Get GMP details, check allotment status, and view live IPO subscriptions. Effortlessly track upcoming and past IPOs with our comprehensive platform."
        />
        <meta property="og:url" content="https://ipodekho.com/privacy-policy" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Privacy Policy - IPO DEKHO" />
        <meta
          property="og:description"
          content="Stay informed with IPO Dekho, your ultimate source for the latest Mainline and SME IPOs. Get GMP details, check allotment status, and view live IPO subscriptions. Effortlessly track upcoming and past IPOs with our comprehensive platform."
        />
        <meta property="og:image" content={IPO_Dekho_Feture_img} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:domain"
          content="https://ipodekho.com/privacy-policy"
        />
        <meta
          property="twitter:url"
          content="https://ipodekho.com/privacy-policy"
        />
        <meta name="twitter:title" content="Privacy Policy - IPO DEKHO" />
        <meta
          name="twitter:description"
          content="Stay informed with IPO Dekho, your ultimate source for the latest Mainline and SME IPOs. Get GMP details, check allotment status, and view live IPO subscriptions. Effortlessly track upcoming and past IPOs with our comprehensive platform."
        />
        <meta name="twitter:image" content={IPO_Dekho_Feture_img} />
        <link rel="canonical" href="https://ipodekho.com/privacy-policy" />
      </Helmet>
      <section className="banner_section">
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-center fw-bold mb-5">Privacy Policy</h1>
              <p>
                IPO Dekho has been developed as a complimentary application,
                provided by IPO Dekho without any associated charges, and is
                intended for use in its present form. This page serves the
                purpose of informing visitors about our policies regarding the
                collection, utilization, and disclosure of Personal Information
                in the event that individuals decide to avail themselves of our
                Service. By opting to utilize our Service, users implicitly
                agree to the gathering and utilization of information as
                outlined in this policy.
              </p>
              <p>
                The Personal Information we collect is employed for the purpose
                of enhancing and delivering a more effective Service. Rest
                assured that we do not engage in the use or dissemination of
                your information to any third party, except as explicitly
                detailed in this Privacy Policy. The terminology employed in
                this Privacy Policy adheres to the same definitions outlined in
                our Terms and Conditions, which can be accessed at IPO Dekho,
                unless explicitly specified otherwise within this Privacy
                Policy.
              </p>
              <h5>Information Collection and Use</h5>
              <p>
                To enhance your user experience with our Service, we may request
                specific personally identifiable information from you. The
                information you provide will be stored and utilized in
                accordance with the guidelines outlined in this privacy policy.
                Additionally, please note that our app employs third-party
                services, which may gather information for identification
                purposes. You can refer to the privacy policies of these
                third-party service providers by following the provided link for
                further details.
              </p>
              <ul>
                <li>
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Google Play Services
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.google.com/admob/answer/6128543?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    AdMob
                  </a>
                </li>
                <li>
                  <a
                    href="https://firebase.google.com/policies/analytics"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Google Analytics for Firebase
                  </a>
                </li>
                <li>
                  <a
                    href="https://firebase.google.com/support/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Firebase Crashlytics
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/about/privacy/update/printable"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </li>
              </ul>
              <h5 style={{ paddingTop: "20px" }}>Log Data</h5>
              <p>
                We want to inform you that whenever you use our Service, in case
                of an error in the app we collect data and information (through
                third-party products) on your phone called Log Data. This Log
                Data may include information such as your device Internet
                Protocol (“IP”) address, device name, operating system version,
                the configuration of the app when utilizing our Service, the
                time and date of your use of the Service, and other statistics.
              </p>
              <h5>Cookies</h5>
              <p>
                Cookies are files with a small amount of data that are commonly
                used as anonymous unique identifiers. These are sent to your
                browser from the websites that you visit and are stored on your
                device’s internal memory. This Service does not use these
                “cookies” explicitly. However, the app may use third party code
                and libraries that use “cookies” to collect information and
                improve their services. You have the option to either accept or
                refuse these cookies and know when a cookie is being sent to
                your device. If you choose to refuse our cookies, you may not be
                able to use some portions of this Service.
              </p>
              <h5>Service Providers</h5>
              <p>
                We may employ third-party companies and individuals due to the
                following reasons:
              </p>
              <ul>
                <li>To facilitate our Service;</li>
                <li>To provide the Service on our behalf;</li>
                <li>To perform Service-related services; or</li>
                <li>To assist us in analyzing how our Service is used.</li>
              </ul>
              <p>
                We want to inform users of this Service that these third parties
                have access to your Personal Information. The reason is to
                perform the tasks assigned to them on our behalf. However, they
                are obligated not to disclose or use the information for any
                other purpose.
              </p>
              <h5>Security</h5>
              <p>
                We value your trust in providing us with your Personal
                Information, thus we are striving to use commercially acceptable
                means of protecting it. But remember that no method of
                transmission over the internet, or method of electronic storage
                is 100% secure and reliable, and we cannot guarantee its
                absolute security.
              </p>
              <h5>Links to Other Sites</h5>
              <p>
                This Service may contain links to other sites. If you click on a
                third-party link, you will be directed to that site. Note that
                these external sites are not operated by us. Therefore, we
                strongly advise you to review the Privacy Policy of these
                websites. We have no control over and assume no responsibility
                for the content, privacy policies, or practices of any
                third-party sites or services.
              </p>
              <h5>Children's Privacy</h5>
              <p>
                These Services do not address anyone under the age of 13. We do
                not knowingly collect personally identifiable information from
                children under 13 years of age. In the case we discover that a
                child under 13 has provided us with personal information, we
                immediately delete this from our servers. If you are a parent or
                guardian and you are aware that your child has provided us with
                personal information, please contact us so that we will be able
                to do the necessary actions.
              </p>
              <h5>Changes to This Privacy Policy</h5>
              <p>
                We may update our Privacy Policy from time to time. Thus, you
                are advised to review this page periodically for any changes. We
                will notify you of any changes by posting the new Privacy Policy
                on this page.
              </p>
              <p>This policy is effective as of 2021-08-14</p>
              <h5>Account Deletion</h5>
              <p>
                If you want to delete your account and all your related data,
                you can send an email to{" "}
                <a href="mailto:info@ipodekho.com" style={{color:'rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1))'}}>info@ipodekho.com</a>{" "}
                requesting deletion. We will delete your data within 14 working
                days of receiving your request.
              </p>
              <p>Note: We don't share you data with any other third party. We collect data for internal purpose only</p>
              <h5>Contact Us</h5>
              <p>
                If you have any questions or suggestions about our Privacy
                Policy, do not hesitate to contact us at info@ipodekho.com
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PrivacyPolicyPage;
